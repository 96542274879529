<template>
  <div class="setting">
    <div class="tips">
      <h2> 推广说明：</h2>
        <p>1：进件所有用户均已完成所有资料的认证，且会向用户展示您的客服联系方式，以便于提升转化率，减少主动联系客户的繁琐操作</p>
       <p> 2：所有进件客户均通过网贷黑名单(2000万数据库)的筛选，如用户为黑名单，虽会进入您的推广列表，但不会消耗您的推广量，请放心使用</p>
       <p>3：如需推广，请提前安排，所有提交的推广计划会在提交24小时后开始执行，请知晓</p>
    </div>
    <setting-form @submit="submitPromote"></setting-form>
    <history-table :list="list" @record="showRecordDialog"  @cond="showCondDialog" @resumeRecord="resumeRecord" @pauseRecord="pauseRecord"></history-table>
    <!-- 分页管理 -->
    <el-pagination
      background
      :page-sizes="pageSizes"
      :page-size="10"
      :current-page="currentPage"
      layout="total, sizes, prev, pager, next"
      :total="page.allNum"
      @size-change="changeSize"
      @current-change="changePage"
    ></el-pagination>
    <!--推广详情-->
        <records
          :is-show="showRecord"
           @view="handleView"
          :promotion-id="showPromotionId"
          @cancel="showRecord = false"
          />
           <cond
        :is-show="showCond"
        :condData="condData"
        @cancel="showCond = false"
        />
  </div>
</template>

<script>
import settingForm from './components/setting-form';
import historyTable from './components/history-table';
import records from "./components/records";
import cond from './components/cond';


import service from './api';
import pagination from '@/mixins/pagination';
import getReports from '@/mixins/getReports';
import view from '@/components/viewBar/view.js';

export default {
  mixins: [pagination, getReports, view],
  data() {
    return {
      page: {},
      list: [],
      showRecord: false,
      showPromotionId: 0,
      showCond: false,
      condData: []
    };
  },
  components: { settingForm, historyTable, records, cond},
  methods: {
    showRecordDialog(id) {
      this.showPromotionId = id;
      this.showRecord = true;
    },
    showCondDialog(data){
      let arr = JSON.parse(data)
      this.condData = arr
      // // console.log(data)

      this.showCond = true;
    },
    submitPromote(param) {
      return service.submit(param).then(() => {
        this.$notify({ type: 'success', message: '设置成功', offset: 400 });
        this.getList({ pageNum: 1 });
        this.getReports();
      });
    },
    getList(params) {
      // 切换分页当前页码
      let { pageNum = 1 } = params;
      this.currentPage = pageNum;

      return service.history(params).then(res => {
        let { size = 10, pages = [], total = 0, records = [] } = res;
        this.page = { allNum: total, pageSize: size, allPage: pages };
        records.forEach((item, index) => {
          item.tempId = (params.pageNum - 1) * this.page.pageSize + index + 1;
        });
        this.list = records;
      });
    },
    //继续推广
    resumeRecord(id){
      this.$alert(
        `是否确认开启推广?`,
        "提示",
        { showCancelButton: true }
      ).then(() => {
        service.resumeRecord({promotionId:id}).then(res => {
          this.$notify({ type: 'success', message: '开启推广成功', offset: 400 });
          this.getList({ pageNum: this.currentPage});
        })
      }).catch()
    },
    //暂停推广
    pauseRecord(id){
      this.$alert(
        `是否确认暂停推广?`,
        "提示",
        { showCancelButton: true }
      ).then(() => {
        service.pauseRecord({promotionId: id}).then(res => {
          this.$notify({ type: 'success', message: '暂停推广成功', offset: 400 });
          this.getList({ pageNum: this.currentPage});
        })
      })
    }
  },
  mounted() {
    this.getList({ pageNum: 1 });
  }
};
</script>

<style lang="scss" scoped>
.setting {
  background-color: #fff;
  padding-bottom: 20px;
  .tips{
    color: #333;
    font-size: 13px;
    text-align: left;
    padding: 30px 0 10px;
    margin: 0 30px 0;
      line-height: 16px;
      border-bottom: 1px solid #d6d6d8;
    h2{
      font-weight: 700;
      margin-bottom: 20px;
    }
    p{
      margin-bottom: 15px;
    }
  }
}
</style>
