<template>
  <div class="records">
    <el-dialog
      title="详情"
      width="70%"
      :visible.sync="isShow"
      :modal="false"
      :close-on-click-modal="false"
      @close="$emit('cancel')"
      @open="openDialog"
    >
        <el-table border :data="condData" :row-style="{ height: 0 }" :cell-style="{ padding: '10px 0'}">
        <el-table-column type="index" label="ID" align="center"></el-table-column>
        <el-table-column prop="name" label="选项" align="center"></el-table-column>
        <el-table-column prop="value" label="条件" align="center"></el-table-column>
        <el-table-column prop="amount" label="星币" align="center">
          <template slot-scope="scope">
            {{scope.row.amount/100}}
          </template>
        </el-table-column>

      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import service from '../api'

import pagination from '@/mixins/pagination';
export default {
  mixins: [pagination],
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    condData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      records: [],
      page: {},
    }
  },
  methods: {

    openDialog() {
      // console.log(this.condData)
    }
  }
}
</script>

<style scoped>

</style>
